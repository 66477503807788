import React from "react";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";

export default function WhitePaper() {
  return (
    <>
      <TitleAndMetaTags
        title="Whitepaper on Conversational AI Chatbot for IT & HR support | Automate IT & HR Processes"
        description="This whitepaper details how AI and automation capabilities in software enables future-proof, sustainable, low-cost, timely, and automated ticket resolution system for IT support and HR support."
        keywords={["Whitepaper"]}
        ogTitle="Whitepaper on Conversational AI Chatbot for IT & HR support | Automate IT & HR Processes"
        ogDescription="This whitepaper details how AI and automation capabilities in software enables future-proof, sustainable, low-cost, timely, and automated ticket resolution system for IT support and HR support."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <section className="skit_contactus sign_up-form_us demo_page webinar_forms_head bg_header_whitepaper">
            <div className="container signup_form">
              <div className="row beta_change_form">
                {/* <h2>Schedule a FREE Demo</h2> */}
                <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 ">
                  <h1>Whitepaper</h1>
                  <h2>
                    Conversational AI and Process Automation to transform
                    employee support.
                  </h2>
                  <p style={{ marginBottom: 5 }}>
                    Businesses have been, over the years, caught up in a
                    customer service frenzy. If there is one takeaway for
                    business from the recent pandemic (Covid-19), it should be
                    that the internal customer(employee) should have equal
                    importance, if not more, than an outside customer. The focus
                    should be on providing exemplary employee services as well.
                  </p>
                  <p style={{ marginBottom: 5 }}>
                    IT support plays a significant role in workplace support,
                    and companies can leverage the benefits of cloud software to
                    meet the IT support needs without having to invest in much
                    of the underlying infrastructure.
                  </p>
                  <p>
                    Speaking of cloud software, conversational AI is the new
                    entrant in innovative software technology. Conversational
                    AI, coupled with AI and automation capabilities, can bring
                    real value to businesses in terms of being a future-proof,
                    sustainable, low-cost, timely, and automated ticket
                    resolution system for IT support and HR support.
                  </p>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 whitepaer_wrapper_form">
                  <div
                    class="sib-form"
                    style="text-align: center;"
                  >
                    <div id="sib-form-container" class="sib-form-container">
                      <div
                        id="sib-container"
                        class="sib-container--large sib-container--vertical"
                        style="text-align:center; background-color:rgba(255,255,255,1); max-width:540px; border-radius:25px; border-width:1px; border-color:#C0CCD9; border-style:solid;"
                      >
                        <form
                          id="sib-form"
                          method="POST"
                          action="https://547c95d4.sibforms.com/serve/MUIFACuFCZNU8GhYFkEks-ruHYhnEBN_h1cFEK1z9Tf3KCcyBQxG3vMFxg5FXBuBSlBeVXORXuc4t7-O8WGYW4SDOKU3K0ypBUGqTqKf6eDqlrS3AMDj-7vCel9StfuNEpgvDla1Vjhta76OYW9jXz41urPZs8WhAdSqFLAFORTE6H06MkxSm1IgdWIoI0hVDMUPdlMVlyqxQsRm"
                        >
                          <div style="padding: 8px 0;">
                            <div class="sib-input sib-form-block">
                              <div class="form__entry entry_block">
                                <div class="form__label-row ">
                                  <label
                                    class="entry__label"
                                    style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                    for="NAME"
                                    data-required="*"
                                  >
                                    Full Name
                                  </label>

                                  <div class="entry__field">
                                    <input
                                      class="input "
                                      maxlength="200"
                                      type="text"
                                      id="NAME"
                                      name="NAME"
                                      autocomplete="off"
                                      placeholder="Type your name"
                                      data-required="true"
                                      required
                                    />
                                  </div>
                                </div>

                                <label
                                  class="entry__error entry__error--primary"
                                  style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div style="padding: 8px 0;">
                            <div class="sib-input sib-form-block">
                              <div class="form__entry entry_block">
                                <div class="form__label-row ">
                                  <label
                                    class="entry__label"
                                    style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                    for="EMAIL"
                                    data-required="*"
                                  >
                                    Business Email
                                  </label>

                                  <div class="entry__field">
                                    <input
                                      class="input "
                                      type="text"
                                      id="EMAIL"
                                      name="EMAIL"
                                      autocomplete="off"
                                      placeholder="Type your company email"
                                      data-required="true"
                                      required
                                    />
                                  </div>
                                </div>

                                <label
                                  class="entry__error entry__error--primary"
                                  style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div style="padding: 8px 0;">
                            <div
                              class="sib-form-block"
                              style='font-size:13px; text-align:left; font-family:"Helvetica", sans-serif; color:#3C4858; background-color:transparent; text-align:left'
                            >
                              <div class="sib-text-form-block">
                                <p style='font-size: 15px;line-height: 1.7;'>
                                  I consent to receive relevant email
                                  communication from Workativ in accordance with
                                  the{" "}
                                  <a
                                    href="https://workativ.com/privacy"
                                    target="_blank"
                                  >
                                    Privacy Policy
                                  </a>{" "}
                                  and understand I can opt-out at any time
                                </p>
                              </div>
                            </div>
                          </div>
                          <div style="padding: 8px 0;">
                            <div
                              class="sib-form-block"
                              style="text-align: left"
                            >
                              <button
                                class="sib-form-block__button sib-form-block__button-with-loader"
                                style='font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#FFFFFF; background-color:#2e6dfa; border-width:0px; width: fit-content;'
                                form="sib-form"
                                type="submit"
                              >
                                <svg
                                  class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                </svg>
                                Read Whitepaper
                              </button>
                            </div>
                          </div>
                          <input
                            type="text"
                            name="email_address_check"
                            value=""
                            class="input--hidden"
                          />
                          <input type="hidden" name="locale" value="en" />
                          <input
                            type="hidden"
                            name="html_type"
                            value="simple"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Sign Up End*/}
            {/*Main Footer Start*/}
          </section>
          {/* <CookiesPoup />
          <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
